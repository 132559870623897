import React from 'react'
import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import { icons } from './assets/icons'
import './polyfill'
import store from './store'
import {MsalProvider} from "@azure/msal-react";
import { withMsal } from "@azure/msal-react";
import { publicClientApplication } from "./util/authConfig";

if (process.env.NODE_ENV === "production")
  console.log = function no_console() {};

React.icons = icons

const AppWithMsal = withMsal(App);

ReactDOM.render(
  <MsalProvider instance={publicClientApplication}>
      <Provider store={store}>
        <AppWithMsal />
      </Provider>
  </MsalProvider>,
  document.getElementById('root')
)
