import { PublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const firefox = ua.indexOf('Firefox')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito
const { protocol, host } = window.location

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AUTH_TENANT}`,
    redirectUri: `${protocol}//${host}/login`,
    postLogoutRedirectUri: `${protocol}//${host}`
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox
  }
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
const loginRequest = {
  scopes: (process.env.REACT_APP_AUTH_SCOPES || '').split(',')
}

const publicClientApplication = new PublicClientApplication(msalConfig)

export { publicClientApplication, loginRequest }

export const useMsalAdmin = () => {
  const { accounts } = useMsal()
  const isAdmin = accounts[0]?.idTokenClaims['roles'].includes('Admin')
  return { isAdmin }
}
